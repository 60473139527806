@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use 'variables' as var;
@include spb.set-typography;

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: 'Titillium Web', sans-serif;
  height: 100%;
  margin: 0 auto;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

@media screen and (min-width: var.$spb-max-width) {
  .spb-holder {
    margin: 0 auto;
    min-width: var.$spb-max-width;
    padding-left: 45px;
    width: 0;
  }
}

@media screen and (max-width: var.$spb-max-width) {
  .spb-holder {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media screen and (max-width: var.$spb-small) {
  .spb-holder {
    margin: 0 auto;
    padding: initial;
    width: 95%;
  }
}


/**
 Small tweak on form fields to make them behave
 */
.sbp-standard-full-width {
  width: 66%;
}
